import React, { Component } from 'react';
import { OauthSender, OauthReceiver } from 'react-oauth-flow';

import { API_ROOT, ROOT, OAUTH_CLIENT_ID, OAUTH_CLIENT_SECRET } from './constants';

const Login = () => (
  <OauthSender
    authorizeUrl={`${API_ROOT}oauth/authorize`}
    clientId={OAUTH_CLIENT_ID}
    redirectUri={`${ROOT}auth`}
    render={({ url }) => <a href={url}>Login</a>}
  />
)

class Authorize extends Component {
  handleSuccess = async (accessToken, { response, state }) => {
    this.props.handleSuccess(response);
  };

  handleError = error => {
    console.error('An error occured');
    console.error(error.message);
  };

  render() {
    return (
      <OauthReceiver
        tokenUrl={`${API_ROOT}oauth/token/`}
        clientId={OAUTH_CLIENT_ID}
        clientSecret={OAUTH_CLIENT_SECRET}
        redirectUri={`${ROOT}auth`}
        onAuthSuccess={this.handleSuccess}
        onAuthError={this.handleError}
        render={({ processing, state, error }) => (
          <div>
            {processing && <p>Authorizing now...</p>}
            {error && (
              <p className="error">An error occured: {error.message}</p>
            )}
          </div>
        )}
      />
    );
  }
}

export { Login, Authorize };
