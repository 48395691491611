import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import { API_PATH } from './constants';
import Listings from './Listings';
import { Login, Authorize } from './Login';

// CSS
import './css/App.scss';

class App extends Component {
  constructor(props) {
    super(props);

    const loginData = localStorage.getItem('login');
    const login = loginData ? JSON.parse(loginData) : null;
    this.state = {
      login: login,
    };
  }

  updateLogin(response) {
    this.setState({ login: response }, () => {
      localStorage.setItem('login', JSON.stringify(response));
    });
  }

  render() {
    return (
      <Router>
        <div className="app container">
          <header className="app-header text-center pb-2">
            <h1 className="text-center pt-2 mb-0">VGOODTHINGS</h1>
            <em>The best of the best on eBay</em>
          </header>
          <section>
            <Route path="/"
                   exact
                   render={(props) => (
                     <Listings login={this.state.login} />
                   )}
            />
            <Route path="/login" component={Login} />
            <Route path="/auth"
                   render={(props) => <Authorize handleSuccess={this.updateLogin.bind(this)} />}
            />
          </section>
        </div>
      </Router>
    );
  }
}

export default App;
